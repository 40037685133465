
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import draggable from 'vuedraggable';

  @Component({
    components: {
      draggable,
    },
  })
export default class ConfirmPopup extends mixins() {
  @Prop({ type: String, default: 'Are you sure you want to cancel?' }) question!: string;
  @Prop({ type: String, default: 'All information will be lost.' }) information!: string;
  @Prop({ type: String, default: 'YES' }) confirmBtnText!: string;
  @Prop({ type: String, default: 'NO' }) cancelBtnText!: string;
}
